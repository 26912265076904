// Objetivo: Criar um modal para agendar mensagens para um ou mais contatos de uma vez só.
import React, {useState, useEffect, useRef, useReducer, useContext} from "react"; //useReducer é um hook que serve para gerenciar estados complexos
import {AuthContext} from "../../context/Auth/AuthContext";
import * as Yup from "yup"; //biblioteca de validação de formulários em react
import {Formik, Form, Field} from "formik"; //biblioteca de formulários em react
import {toast} from "react-toastify"; //biblioteca de notificações em react (toast)

import { //biblioteca de componentes de interface em react
    makeStyles,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Chip,
    IconButton,
    Tooltip,
    InputAdornment
} from "@material-ui/core";

import {CloudUpload as CloudUploadIcon, Delete as DeleteIcon} from "@material-ui/icons";
import {green} from "@material-ui/core/colors";
import {i18n} from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import Autocomplete, {createFilterOptions,} from "@material-ui/lab/Autocomplete";
import {Stack} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import {DateTimePicker} from '@mui/x-date-pickers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textQuickAnswerContainer: {
        width: "100%",
    },
}));

const QuickAnswerSchema = Yup.object().shape({
    shortcut: Yup.string()
        .min(2, "Too Short!")
        .max(15, "Too Long!")
        .required("Required"),
    message: Yup.string()
        .min(8, "Too Short!")
        .max(30000, "Too Long!")
        .required("Required"),
});

const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const filter = createFilterOptions({
    trim: true,
});

const ScheduleModal = ({open, onClose, contact}) => {

    const classes = useStyles();
    //const isMounted = useRef(true);
    const {user} = useContext(AuthContext);

    const [selectedWhatsappId, setSelectedWhatsappId] = useState("");
    const [selectedContact, setSelectedContact] = useState([]);
    const [message, setMessage] = useState("");
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [whatsapps, setWhatsapps] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [contacts, dispatch] = useReducer(reducer, []);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
        const [selectedFile, setSelectedFile] = useState(null);

        const handleFileChange = (event) => {
            setSelectedFile(event.target.files[0]);
        };
    useEffect(() => {
        if (!open) return;
        const delayDebounceFn = setTimeout(() => {
            setScheduleDate(new Date());                     
        }, 100);
        return () => clearTimeout(delayDebounceFn);
    }, [open]);

    useEffect(() => {
        dispatch({type: "RESET"});
        setPageNumber(1);
    }, [searchParam]);

    function fetchWhatsapps() {
        api.get("/whatsapp").then((response) => {
            setWhatsapps(response.data);
        });
    }

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const {data} = await api.get("/contacts/list", {
                        params: {searchParam, pageNumber},
                    });
                    dispatch({type: "LOAD_CONTACTS", payload: data});
                    fetchWhatsapps();
                } catch (err) {
                    console.log('err', err);
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    const renderOptionLabel = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const handleSelectOption = (e, newValue) => {
        setSelectedContact(e => newValue);
    };

    const handleSelectOption2 = (e, newValue) => {
        setSelectedWhatsappId(e => newValue);
    };

    const handleCreateSchedule = async () => {
        const userId = user.id;
        try {
            let contactsId = []
            if (!selectedContact.length) {
                contactsId = [contact.id];
            } else {
                // limite de 20 contatos selecionados
                if (selectedContact.length > 20) {
                    toast.error("Limite de 20 contatos por agendamento");
                    return;
                } else {
                    contactsId = selectedContact.map((contact) => contact.id);
                }
            }

            if (!selectedWhatsappId || !selectedWhatsappId['name']) {
                toast.error("Selecione a Conexão");
                return;
            }

                if (selectedFile) {
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    const {data: file} = await api.post("/files", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );
                    const mediaUrl = file['fullname'];
                    const {data} = await api.post("schedules", { // envia os dados para a api e recebe a resposta da api
                        contactsId,
                        message,
                        scheduleDate,
                        mediaUrl,
                        whatsappName: selectedWhatsappId['name'],
                        userId
                    });

                } else {

                    const {data} = await api.post("schedules", { // envia os dados para a api e recebe a resposta da api
                        contactsId,
                        message,
                        scheduleDate,
                        whatsappName: selectedWhatsappId['name'],
                        userId
                    });
                }

            toast.success("Agendamento criado com sucesso!");
            handleClose();
        } catch (err) {
            console.log('deu erro ===> ', err);
            toastError(err);
        }
    }

    const handleClose = () => {
        onClose();
        setMessage("");
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        console.log('scrolling');
        if (!hasMore || loading) return;
        const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                //scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    Adicionar novo agendamento
                </DialogTitle>

                <DialogContent dividers>
                    <Stack direction={'column'} spacing={2}>
                        <Autocomplete
                            sx={{maxWidth: '100px'}}
                            options={contacts}
                            multiple={!contact}
                            limitTags={2}

                            id="multiple-limit-tags"
                            getOptionLabel={renderOptionLabel}
                            onChange={(e, newValue) => handleSelectOption(e, newValue)}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        {...getTagProps({index})}

                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params}
                                           label={i18n.t("newTicketModal.fieldLabel")}
                                           variant="outlined"
                                           autowidth
                                           placeholder="Contatos (Máximo 20)"
                                           onScroll={handleScroll}

                                />
                            )}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                            <DateTimePicker
                                value={scheduleDate}
                                onChange={(newValue) => {
                                    setScheduleDate(newValue)
                                }}
                                label="Data e hora do agendamento"
                                renderInput={(params) => <TextField variant="outlined" {...params} />}
                            />
                        </LocalizationProvider>
                        <Autocomplete
                            sx={{maxWidth: '100px'}}
                            options={whatsapps}
                            multiple={false}
                            id="multiple-limit-tags"
                            getOptionLabel={renderOptionLabel}
                            onChange={(e, newValue) => handleSelectOption2(e, newValue)}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        {...getTagProps({index})}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params}
                                           label={i18n.t("Escolha a conexão")}
                                           variant="outlined"
                                           autowidth
                                           placeholder="Conexão"
                                           onScroll={handleScroll}
                                />
                            )}
                        />
                    </Stack>
                    <div className={classes.textQuickAnswerContainer}>
                        <TextField
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            label={'Mensagem a ser enviada'}
                            variant="outlined"
                            margin="dense"
                            className={classes.textField}
                            multiline
                            rows={7}
                            fullWidth
                        />
                        </div>
                        <DialogActions>
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" component="span" startIcon={<CloudUploadIcon/>}>
                                    {selectedFile ? selectedFile.name : "ANEXAR ARQUIVO"}
                                </Button>
                            </label>
                            <div style={{display: selectedFile ? "flex" : "none", alignItems: "center"}}>
                                <Tooltip title="Remover arquivo" placement="top">
                                    <IconButton onClick={() => setSelectedFile(null)} color="error">
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <input
                                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.txt,.csv,.xls,.xlsx,.ppt,.pptx,.mp3,.mp4,.avi,.mov,.ogg,.wmv"
                                style={{display: "none"}}
                                id="contained-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                        </DialogActions>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                    >
                        {i18n.t("quickAnswersModal.buttons.cancel")}
                    </Button>
                    <Button
                        onClick={handleCreateSchedule}
                        color="primary"
                        variant="contained"
                        className={classes.btnWrapper}
                    >
                        {i18n.t("quickAnswersModal.buttons.okAdd")}
                    </Button>
                </DialogActions>


            </Dialog>
        </div>
    );
};

export default ScheduleModal;
