// Objetivo: Listar as filas cadastradas no sistema e permitir a criação, edição e exclusão de filas.
import React, { useEffect, useReducer, useState } from "react"; //useReducer é um hook que permite que você trabalhe com estados complexos de uma forma mais organizada.

import openSocket from "../../services/socket-io"; //importa o socket.io-client e cria uma conexão com o servidor socket.io

import {            // importa os componentes do material-ui que serão utilizados
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer"; //importa os componentes criados para o projeto
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import QueueModal from "../../components/QueueModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({ //cria um objeto de estilos para serem utilizados nos componentes abaixo
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {      //cria um reducer para tratar os estados da aplicação
  if (action.type === "LOAD_QUEUES") {    //verifica se o tipo da ação é LOAD_QUEUES
    const queues = action.payload;        //armazena as filas recebidas na action
    const newQueues = [];                 //cria um array vazio para armazenar as novas filas

    queues.forEach((queue) => {     //percorre as filas recebidas
      const queueIndex = state.findIndex((q) => q.id === queue.id); //verifica se a fila já existe no estado da aplicação
      if (queueIndex !== -1) {            //se a fila já existe
        state[queueIndex] = queue;        //atualiza a fila
      } else {                            //se a fila não existe
        newQueues.push(queue);            //adiciona a fila no array de novas filas
      }
    });

    return [...state, ...newQueues];      //retorna o estado atualizado com as novas filas
  }

  if (action.type === "UPDATE_QUEUES") {    //verifica se o tipo da ação é UPDATE_QUEUES
    const queue = action.payload;           //armazena a fila recebida na action
    const queueIndex = state.findIndex((u) => u.id === queue.id); //verifica se a fila já existe no estado da aplicação

    if (queueIndex !== -1) {        //se a fila já existe no estado da aplicação
      state[queueIndex] = queue;    //atualiza a fila
      return [...state];            //retorna o estado atualizado
    } else {                        //se a fila não existe no estado da aplicação
      return [queue, ...state];     //adiciona a fila no estado da aplicação
    }
  }

  if (action.type === "DELETE_QUEUE") {         // verifica se o tipo da ação é DELETE_QUEUE
    const queueId = action.payload;             // armazena o id da fila recebida na action
    const queueIndex = state.findIndex((q) => q.id === queueId);  // verifica se a fila já existe no estado da aplicação
    if (queueIndex !== -1) {                    // se a fila já existe no estado da aplicação
      state.splice(queueIndex, 1);              // remove a fila do estado da aplicação
    }
    return [...state]; //retorna o estado atualizado
  }

  if (action.type === "RESET") { //verifica se o tipo da ação é RESET
    return []; //retorna o estado vazio
  }
};

const Queues = () => {            // cria o componente Queues
  const classes = useStyles();    // armazena os estilos do componente em uma constante

  const [queues, dispatch] = useReducer(reducer, []); //cria um estado para armazenar as filas e um dispatch para atualizar o estado
  const [loading, setLoading] = useState(false); //cria um estado para armazenar o status de carregamento

  const [queueModalOpen, setQueueModalOpen] = useState(false);      // cria um estado para armazenar o status do modal de filas
  const [selectedQueue, setSelectedQueue] = useState(null);                 // cria um estado para armazenar a fila selecionada
  const [confirmModalOpen, setConfirmModalOpen] = useState(false); // cria um estado para armazenar o status do modal de confirmação

  useEffect(() => { //executa a função abaixo quando o componente é montado
    (async () => { //cria uma função assíncrona
      setLoading(true); //atualiza o estado de carregamento para true
      try { //tenta executar a função abaixo
        const { data } = await api.get("/queue"); //faz uma requisição para a api para buscar as filas cadastradas
        dispatch({ type: "LOAD_QUEUES", payload: data }); //atualiza o estado das filas com as filas recebidas

        setLoading(false); //atualiza o estado de carregamento para false
      } catch (err) { //se ocorrer algum erro na execução da função acima
        toastError(err); //exibe um toast com o erro
        setLoading(false); //atualiza o estado de carregamento para false
      }
    })();
  }, []);

  useEffect(() => { //executa a função abaixo quando o componente é montado
    const socket = openSocket(); //cria uma conexão com o socket

    socket.on("queue", (data) => { // escuta o evento "queue" do socket
      if (data.action === "update" || data.action === "create") { // verifica se a ação é "update" ou "create"
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue }); // atualiza o estado das filas com a fila recebida
      }

      if (data.action === "delete") { //verifica se a ação é "delete"
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId }); //remove a fila do estado das filas
      }
    });

    return () => { //executa a função abaixo quando o componente é desmontado
      socket.disconnect(); //encerra a conexão com o socket
    };
  }, []);

  const handleOpenQueueModal = () => { //cria uma função para abrir o modal de filas
    setQueueModalOpen(true); //atualiza o estado do modal de filas para true
    setSelectedQueue(null); //atualiza a fila selecionada para null
  };

  const handleCloseQueueModal = () => { //cria uma função para fechar o modal de filas
    setQueueModalOpen(false); //atualiza o estado do modal de filas para false
    setSelectedQueue(null); //atualiza a fila selecionada para null
  };

  const handleEditQueue = (queue) => { //cria uma função para editar uma fila
    setSelectedQueue(queue); //atualiza a fila selecionada com a fila recebida
    setQueueModalOpen(true); //atualiza o estado do modal de filas para true
  };

  const handleCloseConfirmationModal = () => { //cria uma função para fechar o modal de confirmação
    setConfirmModalOpen(false); //atualiza o estado do modal de confirmação para false
    setSelectedQueue(null); //atualiza a fila selecionada para null
  };

  const handleDeleteQueue = async (queueId) => { //cria uma função para deletar uma fila
    try { //tenta executar a função abaixo
      await api.delete(`/queue/${queueId}`); //faz uma requisição para a api para deletar a fila com o id recebido

      //console.log("chamando a rota delete do WorkingHours");
      //await api.delete(`/workinghours0/${queueId}`); //faz uma requisição para a api para deletar os horários de trabalho da fila com o id recebido
      
      toast.success(i18n.t("Queue deleted successfully!")); //exibe um toast de sucesso
    } catch (err) { //se ocorrer algum erro na execução da função acima
      toastError(err); //exibe um toast com o erro
    }
    setSelectedQueue(null); //atualiza a fila selecionada para null
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${
            selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queues.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <QueueModal
        open={queueModalOpen}
        onClose={handleCloseQueueModal}
        queueId={selectedQueue?.id}
      />
      <MainHeader>
        <Title>{i18n.t("queues.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenQueueModal}
          >
            {i18n.t("queues.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("Id")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.color")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.greeting")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.fatherqueue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {queues.map((queue) => (
                <TableRow key={queue.id}>
                  <TableCell align="center">{queue.id}</TableCell>
                  <TableCell align="center">{queue.name}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: queue.color,
                          width: 60,
                          height: 20,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {queue.greetingMessage}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">{queue.fatherqueue}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditQueue(queue)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedQueue(queue);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Queues;
