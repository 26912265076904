import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import Print from "@material-ui/icons/Print";
import CodeIcon from '@material-ui/icons/Code';
import { TuneRounded } from "@material-ui/icons";


import BookmarksIcon from "@material-ui/icons/Bookmarks";
import Schedule from "@material-ui/icons/ScheduleSharp";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListIcon from "@material-ui/icons/ListAlt";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <li>
            <ListItem button component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const MainListItems = (props) => {
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);

    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    return (
        <div onClick={drawerClose}>
            <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon />}
            />

            <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon />}
            />
            <ListItemLink
                to="/quickAnswers"
                primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                icon={<QuestionAnswerOutlinedIcon />}
            />
            <ListItemLink
                to="/schedules"
                primary={'Agendamentos'}
                icon={<Schedule />}
            />
            <ListItem onClick={() => history.push("/tags")} button>
                <ListItemIcon>
                    <BookmarksIcon />
                </ListItemIcon>
                <ListItemText primary="Tags" />
            </ListItem>
            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        <Divider />
                        <ListSubheader inset>
                            {i18n.t("mainDrawer.listItems.administration")}
                        </ListSubheader>

                        <ListItem
                            dense
                            button
                            onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                        >
                            <ListItemIcon>
                                <LocalOfferIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t("mainDrawer.listItems.tags")}
                            />
                            {openCampaignSubmenu ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )}
                        </ListItem>
                        <Collapse
                            style={{ paddingLeft: 15 }}
                            in={openCampaignSubmenu}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List dense component="div" disablePadding>
                                <ListItem onClick={() => history.push("/tags")} button>
                                    <ListItemIcon>
                                        <BookmarksIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Lista de tags" />
                                </ListItem>

                                <ListItem
                                    onClick={() => history.push("/tags-reports")}
                                    button
                                >
                                    <ListItemIcon>
                                        <ListIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Relatórios" />
                                </ListItem>


                            </List>

                        </Collapse>

                        <ListItemLink
                            to="/"
                            primary="Dashboard"
                            icon={<DashboardOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/connections"
                            primary={i18n.t("mainDrawer.listItems.connections")}
                            icon={
                                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                    <SyncAltIcon />
                                </Badge>
                            }
                        />

                        <ListItemLink
                            to="/users"
                            primary={i18n.t("mainDrawer.listItems.users")}
                            icon={<PeopleAltOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<AccountTreeOutlinedIcon />}
                        />
                        {user.name === "Suporte_Centerchat" &&
                            <ListItemLink
                                to="/api"
                                primary={i18n.t("mainDrawer.listItems.api")}
                                icon={
                                    <CodeIcon />
                                }
                            />
                        }
                        {user.name === "Suporte_Centerchat" &&

                            <ListItemLink
                                to="/settings"
                                primary={i18n.t("mainDrawer.listItems.settings")}
                                icon={<SettingsOutlinedIcon />}
                            />
                        }
                        {user.name === "Suporte_Centerchat" &&
                        //ACRESCENTEI AO MENU LATERAL
                            <ListItemLink
                                to="/controlPanel"
                                primary="Painel de Controle"//{i18n.t("mainDrawer.listItems.settings")}
                                icon={<TuneRounded />}
                            />
                        }
                    </>
                )}
            />
            <Can
                role={user.profile}
                perform="drawer-superv-items:view"
                yes={() => (
                    <>
                        <Divider />
                        <ListSubheader inset>
                            {i18n.t("Supervisor")}
                        </ListSubheader>
                        <ListItemLink
                            to="/connections"
                            primary={i18n.t("mainDrawer.listItems.connections")}
                            icon={
                                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                                    <SyncAltIcon />
                                </Badge>
                            }
                        />
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
