import React from "react";
import { useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import api from "../../services/api";
import { Button, Grid } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: theme.spacing(8, 8, 3),
    },

    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        //direction: "column",
        marginBottom: 12,

    },

    settingOption: {
        marginLeft: "auto",
    },
    margin: {
        margin: theme.spacing(1),
    },
    formItem: {
        display: "flex",
        direction: "column",
        marginBottom: theme.spacing(2),
    },
    buttonContainer: {
        textAlign: "right",
        padding: theme.spacing(1),
    },

}));

const ControlPanel = () => {
    const classes = useStyles();

    const [settings, setSettings] = useState([]);
    const [blocked, setBlocked] = useState(false);
    const [blockedDate, setBlockedDate] = useState("");

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/settings");
                setSettings(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const socket = openSocket();

        socket.on("settings", data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleChangeSetting = async e => {
        const selectedValue = e.target.value;
        const settingKey = e.target.name;

        try {
            await api.put(`/settings/${settingKey}`, {
                value: selectedValue,

            });

            if (settingKey === "blocked" && selectedValue === "1") {

                const date = new Date();                
                const day = date.getDate() + 5;
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const newDate = `${day}/0${month}/${year}`;

                const key = "blockedDate";

                await api.put(`/settings/${key}`, {
                    value: newDate,
                });

            } else {

                const key = "blockedDate";
                await api.put(`/settings/${key}`, {
                    value: "",
                });

            }            
            
        } catch (err) {
            toastError(err);
        }
    };

    const getSettingValue = key => {
        const { value } = settings.find(s => s.key === key);
        return value;
    };

    //função para calcular a data de bloqueio se blocked for true
   /* const calculateBlockedDate = () => {
        if (!blocked) {
            setBlockedDate("");
        } else {
            const date = new Date();
            const newDate = date.setDate(date.getDate() + 5);
            console.log(newDate);
            setBlockedDate(newDate);
        }
    };*/

    const handleSubmit = async () => {
        try {
            toast.success(i18n.t("settings.success"));
        } catch (err) {
            toastError(err);
        }
    }


    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth="sm">
                <h2>Painel de Controle</h2>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Typography variant="body1">
                            Faturas em atraso
                        </Typography>
                        <Select
                            margin="dense"
                            variant="outlined"
                            native
                            id="payment-setting"
                            name="payment"
                            value={
                                settings && settings.length > 0 && getSettingValue("payment")
                            }
                            className={classes.settingOption}
                            onChange={handleChangeSetting}
                        >
                            <option value="0">
                                Sem faturas em atraso
                            </option>
                            <option value="1">
                                1 fatura em atraso
                            </option>
                            <option value="2">
                                2 faturas em atraso
                            </option>
                        </Select>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>

                        <Typography variant="body1">
                            Bloquear usuário
                        </Typography>
                        <Select
                            margin="dense"
                            variant="outlined"
                            native
                            id="blocked-setting"
                            name="blocked"
                            value={
                                settings && settings.length > 0 && getSettingValue("blocked")
                            }
                            className={classes.settingOption}
                            onChange={handleChangeSetting}
                        >
                            <option value="0">
                                Não
                            </option>
                            <option value="1">
                                Sim
                            </option>
                        </Select>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>

                        <Typography variant="body1">
                            Data efetiva do bloqueio
                        </Typography>

                        <TextField
                            id="blockedDate"
                            name="blockedDate"
                            label="Data de bloqueio"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={settings && settings.length > 0 && getSettingValue("blockedDate")}                          
                            disabled={true}

                        />


                    </Grid>
                </Paper>

                <div className={classes.buttonContainer}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                        onClick={handleSubmit}
                    >
                        Salvar
                    </Button>
                </div>

            </Container >
        </div >
    );
};

export default ControlPanel;
