// Objetivo: Criar um modal para adicionar/editar um WhatsApp e suas configurações de fila e mensagens de saudação, despedida e fora do horário de atendimento.
import React, { useState, useEffect } from "react"; // useState: Hook que permite adicionar estado a um componente funcional. useEffect: Hook que permite executar efeitos colaterais em componentes funcionais.
import * as Yup from "yup"; // Yup: Biblioteca para validação de formulários.
import { Formik, Form, Field } from "formik"; // Formik: Biblioteca para gerenciamento de formulários.
import { toast } from "react-toastify"; // toast: Biblioteca para exibir mensagens de erro, sucesso, etc.

import { makeStyles } from "@material-ui/core/styles"; // makeStyles: Hook para estilizar componentes. Theme: Objeto com as propriedades de estilo.
import { green } from "@material-ui/core/colors"; // green: Cor verde do Material UI. CircularProgress: Componente de progresso circular. TextField: Componente de campo de texto.
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { // Componentes de diálogo do Material UI.
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
	Tabs,
	Tab,
	Paper,
	Typography,
} from "@material-ui/core";

import { Divider } from "@mui/material"; // Divider: Componente de divisória do Material UI.

import api from "../../services/api"; // api: API para comunicação com o backend.
import { i18n } from "../../translate/i18n"; // i18n: Tradução de idiomas.
import toastError from "../../errors/toastError"; // toastError: Função para exibir mensagens de erro.
import QueueSelect from "../QueueSelect"; // QueueSelect: Componente de seleção de filas.
import WorkingHoursForm from "../WorkingHoursForm";
import { set } from "date-fns";

const useStyles = makeStyles(theme => ({ // useStyles: Função para estilizar componentes.
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const SessionSchema = Yup.object().shape({ // SessionSchema: Objeto com as propriedades de validação do formulário.
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => { // WhatsAppModal: Componente de modal de WhatsApp.
	const classes = useStyles();
	const initialState = {
		name: "",
		greetingMessage: "",
		farewellMessage: "",
		openingHours: "",
		closingHours: "",
		outServiceMessage: "",
		isDefault: false,
		greetingNPS: "",
		farewellNPS: "",
		useNPS: false,
		expiresTicketNPS: "",
		mediaUrl: "",
		lunchTimeMessage: "", //ACRESCENTADO MENSAGEM DE HORÁRIO DE ALMOÇO
		isMediaUrl: false,
	};
	const [whatsApp, setWhatsApp] = useState(initialState); // whatsApp: Estado de WhatsApp. setWhatsApp: Função para atualizar o estado de WhatsApp.
	const [selectedQueueIds, setSelectedQueueIds] = useState([]); // selectedQueueIds: Estado de filas selecionadas. setSelectedQueueIds: Função para atualizar o estado de filas selecionadas.
	const [selectedFile, setSelectedFile] = useState(null); // selectedFile: Estado de arquivo selecionado. setSelectedFile: Função para atualizar o estado de arquivo selecionado.
	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setMediaFileName(event.target.files[0].name);
	}; // handleFileChange: Função para alterar arquivo.
	const [WorkingHours, setWorkingHours] = useState([]); //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [tab, setTab] = useState(0); //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [isWhatsappSaved, setIsWhatsappSaved] = useState(false); //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [savedWhatsappId, setSavedWhatsappId] = useState(null);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [wkWhatsappId, setWkWhatsappId] = useState(null);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [mediaFileName, setMediaFileName] = useState("");//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
	const [mediaFile, setMediaFile] = useState(null);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO

	useEffect(() => { // useEffect: Hook que permite executar efeitos colaterais em componentes funcionais.
		const fetchSession = async () => { // fetchSession: Função para buscar sessão.
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`); // data: Dados da sessão.
				setWhatsApp(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id); // whatsQueueIds: Estado de filas do WhatsApp.
				setSelectedQueueIds(whatsQueueIds);

				if (data.mediaUrl) {
					const mediaName = data.mediaUrl.split("/").pop();
					setMediaFileName(mediaName);
					setSelectedFile("temarquivo")
					setMediaFile(data.mediaUrl);
				}

				setWkWhatsappId(whatsAppId); //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
				setIsWhatsappSaved(true);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO

			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId, open]);

	const handleSaveWhatsApp = async values => { // handleSaveWhatsApp: Função para salvar WhatsApp.
		const whatsappData = { ...values, queueIds: selectedQueueIds }; // whatsappData: Dados do WhatsApp.

		try {
			let savedWhatsappId; //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO



			if (selectedFile === "temarquivo") {

				whatsappData.mediaUrl = mediaFile;

			} else if (selectedFile && selectedFile !== "temarquivo") {

				const formData = new FormData();
				formData.append("file", selectedFile, selectedFile.name.replace(/\s+/g, "_"));

				const { data: file } = await api.post("/files", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				const dataf = file['fullname'];
				whatsappData.mediaUrl = dataf;
				
			} else {
				whatsappData.mediaUrl = null;
			}

			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
				setSavedWhatsappId(whatsAppId); //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
			} else {
				const response = await api.post("/whatsapp", whatsappData);//ALTERADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
				savedWhatsappId = response.data.id; //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
				setSavedWhatsappId(savedWhatsappId);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
			}
			toast.success(i18n.t("whatsappModal.success"));
			setTab(1);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
			setIsWhatsappSaved(true);//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
			return savedWhatsappId;//ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
		} catch (err) {
			toastError(err);
		}
		setSelectedFile(null);
		setSelectedQueueIds([]);
		setMediaFile(null);
	};

	const handleClose = () => { //FUNÇÃO ALTERADA NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
		onClose();
		setWhatsApp(initialState);
		setWorkingHours([]);
		setIsWhatsappSaved(false);
		setSavedWhatsappId(null);
		setWkWhatsappId(null);
		setTab(0);
		setMediaFileName("");
		setMediaFile(null);
	};

	const handleSaveWorkingHours = async (values) => { //FUNÇÃO ACRESNTADA NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO

		if (!isWhatsappSaved) {
			toast.error("Por favor, salve os dados da Conexão primeiro.");
			return;
		}

		toast.success("Horários salvos com sucesso.");

		handleClose();
		setWorkingHours(values);
		setTab(0);
	};



	return ( // Retorna o modal de WhatsApp.
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md" //ALTERADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Tabs //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
					value={tab}
					indicatorColor="primary"
					textColor="primary"
					onChange={(_, v) => setTab(v)}
					aria-label="disabled tabs example"
				>
					<Tab label="Dados da Conexão" />
					<Tab label="Horários de Atendimento" />
				</Tabs>
				{tab === 0 && ( //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
					<Paper>
						<Formik
							initialValues={whatsApp}
							enableReinitialize={true}
							validationSchema={SessionSchema}
							onSubmit={(values, actions) => {
								setTimeout(() => { //FUNÇÃO ALTERADA NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
									const savedWhatsappId = handleSaveWhatsApp(values);
									if (savedWhatsappId) {
										actions.setSubmitting(false);
									}
								}, 400);
							}}
						>
							{({ values, touched, errors, isSubmitting }) => (
								<Form>
									<DialogContent dividers>
										<div className={classes.multFieldLine}>
											<Field
												as={TextField}
												label={i18n.t("whatsappModal.form.name")}
												autoFocus
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												className={classes.textField}
											/>
											<FormControlLabel
												control={
													<Field
														as={Switch}
														color="primary"
														name="isDefault"
														checked={values.isDefault}
													/>
												}
												label={i18n.t("whatsappModal.form.default")}
											/>
										</div>
										<div>
											<Field
												as={TextField}
												label={i18n.t("queueModal.form.greetingMessage")}
												type="greetingMessage"
												multiline
												rows={5}
												fullWidth
												name="greetingMessage"
												error={
													touched.greetingMessage && Boolean(errors.greetingMessage)
												}
												helperText={
													touched.greetingMessage && errors.greetingMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
										<div>
											{/*	Botão de anexar aquivo */}
											<DialogActions>
												<label htmlFor="contained-button-file">
													<Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
														{mediaFileName ? mediaFileName : "ÁUDIO DE SAUDAÇÃO"}
													</Button>
												</label>
												<div style={{ display: mediaFileName ? "flex" : "none", alignItems: "center" }}>
													<Tooltip title="Remover arquivo" placement="top">
														<IconButton onClick={() => {
															setSelectedFile(null);
															setMediaFileName("");
														}}
															color="error">
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</div>
												<input
													accept=".mp3,.ogg"
													style={{ display: "none" }}
													id="contained-button-file"
													type="file"
													onChange={handleFileChange}
												/>
											</DialogActions>
										</div>
										<div>
											<Field
												as={TextField}
												label={i18n.t("whatsappModal.form.farewellMessage")}
												type="farewellMessage"
												multiline
												rows={5}
												fullWidth
												name="farewellMessage"
												error={
													touched.farewellMessage && Boolean(errors.farewellMessage)
												}
												helperText={
													touched.farewellMessage && errors.farewellMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
										{/*<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.openingHours")}
										type="time"
										name="openingHours"
										error={
											touched.openingHours && Boolean(errors.openingHours)
										}
										helperText={
											touched.openingHours && errors.openingHours
										}
										variant="outlined"
										margin="dense"
									/>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.closingHours")}
										type="time"
										name="closingHours"
										error={
											touched.closingHours && Boolean(errors.closingHours)
										}
										helperText={
											touched.closingHours && errors.closingHours
										}
										variant="outlined"
										margin="dense"
										padding-left="20px"
									/>
									</div>*/}
										<div>
											<Field
												as={TextField}
												label={i18n.t("whatsappModal.form.outServiceMessage")}
												type="outServiceMessage"
												multiline
												rows={5}
												fullWidth
												name="outServiceMessage"
												error={
													touched.outServiceMessage && Boolean(errors.outServiceMessage)
												}
												helperText={
													touched.outServiceMessage && errors.outServiceMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
										<div> {/*ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO*/}
											<Field
												as={TextField}
												label={i18n.t("whatsappModal.form.lunchTimeMessage")}
												type="lunchTimeMessage"
												multiline
												rows={5}
												fullWidth
												name="lunchTimeMessage"
												error={
													touched.lunchTimeMessage && Boolean(errors.lunchTimeMessage)
												}
												helperText={
													touched.lunchTimeMessage && errors.lunchTimeMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={selectedIds => setSelectedQueueIds(selectedIds)}
										/>

										<Divider sx={{ my: 2 }} ></Divider>

										<div className={classes.multFieldLine}>

											<Field
												disabled={!values.useNPS}
												as={TextField}
												label={'Fecha NPS após x minutos'}
												autoFocus
												name="expiresTicketNPS"
												error={touched.expiresTicketNPS && Boolean(errors.expiresTicketNPS)}
												helperText={touched.expiresTicketNPS && errors.expiresTicketNPS}
												variant="outlined"
												margin="dense"
												className={classes.textFieldTime}

											/>

											<FormControlLabel
												control={
													<Field
														as={Switch}
														color="primary"
														name="useNPS"
														checked={values.useNPS}
													/>
												}
												label={'Utilizar NPS'}
											/>

										</div>

										<div>
											<Field
												disabled={!values.useNPS}
												as={TextField}
												label={'Mensagem de NPS'}
												multiline
												rows={5}
												fullWidth
												name="greetingNPS"
												error={touched.greetingNPS && Boolean(errors.greetingNPS)}
												helperText={touched.greetingNPS && errors.greetingNPS}
												variant="outlined"
												margin="dense"
											/>
										</div>
										<div>
											<Field
												disabled={!values.useNPS}
												as={TextField}
												label={'Mensagem de despedida NPS'}
												multiline
												rows={5}
												fullWidth
												name="farewellNPS"
												error={touched.farewellNPS && Boolean(errors.farewellNPS)}
												helperText={touched.farewellNPS && errors.farewellNPS}
												variant="outlined"
												margin="dense"
											/>
										</div>

									</DialogContent>
									<DialogActions>
										<Button
											onClick={handleClose}
											color="secondary"
											disabled={isSubmitting}
											variant="outlined"
										>
											{i18n.t("whatsappModal.buttons.cancel")}
										</Button>
										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
											variant="contained"
											className={classes.btnWrapper}
										>
											{whatsAppId
												? i18n.t("whatsappModal.buttons.okEdit")
												: i18n.t("whatsappModal.buttons.okAdd")}
											{isSubmitting && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</Button>
									</DialogActions>
								</Form>
							)}
						</Formik>
					</Paper>)}

				{tab === 1 && ( //ACRESCENTADO NA ALTERAÇÃO DE HORÁRIOS DE ATENDIMENTO
					<Paper style={{ padding: 20 }}>
						<div>
							<Typography variant="h6">Horários de Atendimento</Typography>
							<WorkingHoursForm
								initialValues={WorkingHours}
								onSubmit={handleSaveWorkingHours}
								savedQueueId={0}
								savedWhatsappId={savedWhatsappId}
								wkId={wkWhatsappId}
							/>
						</div>
					</Paper>
				)}

			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
