//Objetivo: Formulário para cadastro de horários e dia de trabalho
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, TextField, Checkbox, FormControlLabel, Table, TableHead, TableBody, TableCell, TableRow, Button } from "@material-ui/core";
import { Formik, Form, Field, FieldArray } from "formik";
import { isArray } from "lodash";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    ticketsWrapper: {
        position: "relative",
        top: 0,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    badge: {
        right: "-10px",
    },
    buttonContainer: {
        textAlign: "right",
        padding: theme.spacing(1),
    },

}));

function WorkingHoursForm({ initialValues, onSubmit, savedQueueId, savedWhatsappId, wkId }) {

    const classes = useStyles();

    const [WorkingHours, setWorkingHours] = useState([        
        { day: 1, name: "monday", selected: false, openingHours: "08:00", lunchStart: "12:00", lunchEnd: "13:00", closingHours: "18:00" },
        { day: 2, name: "tuesday", selected: false, openingHours: "08:00", lunchStart: "12:00", lunchEnd: "13:00", closingHours: "18:00" },
        { day: 3, name: "wednesday", selected: false, openingHours: "08:00", lunchStart: "12:00", lunchEnd: "13:00", closingHours: "18:00" },
        { day: 4, name: "thursday", selected: false, openingHours: "08:00", lunchStart: "12:00", lunchEnd: "13:00", closingHours: "18:00" },
        { day: 5, name: "friday", selected: false, openingHours: "08:00", lunchStart: "12:00", lunchEnd: "13:00", closingHours: "18:00" },
        { day: 6, name: "saturday", selected: false, openingHours: "08:00", lunchStart: "", lunchEnd: "", closingHours: "12:00" },
        { day: 0, name: "sunday", selected: false, openingHours: "", lunchStart: "", lunchEnd: "", closingHours: "" },
    ]);

    useEffect(() => {
        if (isArray(initialValues) && initialValues.length > 0) {
            setWorkingHours(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    /*const handleSubmit = (data) => {
        onSubmit(data);
    };*/

    useEffect(() => {
        (async () => {
            if (wkId !== null) {
                try {
                    console.log(wkId);
                    const { data } = await api.get(`/workinghours/${wkId}`);
                    console.log(data);
                    setWorkingHours(data);
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [wkId]);

    const reorderedWorkingHours = useMemo(() => {
        // Filtrando e removendo o dia 0 do array
        const filteredWorkingHours = WorkingHours.filter(item => item.day !== 0);
        // Encontrando o dia 0 e adicionando ao final do array
        const dayZero = WorkingHours.find(item => item.day === 0);
        if (dayZero) {
            filteredWorkingHours.push(dayZero);
        }
        return filteredWorkingHours;
    }, [WorkingHours]);


    const handleChange = (index, field, value) => {
        setWorkingHours(prev => {
            return prev.map((item, i) => {
                if (i === index) {
                    return { ...item, [field]: value };
                }
                return item;

            });
        });
    };

    const handleSubmit = async (data) => {
        onSubmit(data);
        //console.log(data);
        //console.log(savedQueueId);        
        //const workinghoursdata = {...data, queueId: savedQueueId, whatsappId: 0};
        data.forEach(async (element) => {
            //console.log(element);
            //se selected for false, os campos de horário são preenchidos com vazio
            if (!element.selected) {
                element.openingHours = "";
                element.lunchStart = "";
                element.lunchEnd = "";
                element.closingHours = "";
            }
            if (wkId !== null) {
                console.log("Vai chamar o put");
                await api.put(`/workinghours/${savedWhatsappId}`, element);
            } else if (savedWhatsappId !== null) {
                const workinghoursdata = { ...element, queueId: savedQueueId, whatsappId: savedWhatsappId };
                console.log(workinghoursdata);
                await api.post("/workinghours", workinghoursdata);
            }
        });

        if (savedWhatsappId !== null) {
            setWorkingHours([]);
        } else {
            setWorkingHours([initialValues])
        }
    };

    return (
        <Formik
            enableReinitialize
            className={classes.fullWidth}
            initialValues={{ WorkingHours }}
            onSubmit={({ WorkingHours }) =>
                setTimeout(() => {
                    handleSubmit(WorkingHours);
                }, 500)
            }
        >
            {({ values, touched, errors }) => (
                <Form className={classes.fullWidth}>
                    <FieldArray
                        name="WorkingHours"
                        render={(arrayHelpers) => (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">Dia</TableCell>
                                        <TableCell align="center">Hora Início</TableCell>
                                        <TableCell align="center">Início almoço</TableCell>
                                        <TableCell align="center">Fim almoço</TableCell>
                                        <TableCell align="center">Hora Fim</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reorderedWorkingHours.map((dayData, index) => (
                                        <TableRow key={dayData.day}>
                                            <TableCell align="center">
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            as={Checkbox}
                                                            color="primary"
                                                            name={`WorkingHours[${index}].selected`}
                                                            checked={dayData.selected}
                                                            onChange={(e) => handleChange(index, "selected", e.target.checked)}
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="center">{i18n.t(`whatsappModal.form.WorkingHours.day.${dayData.day}`)}</TableCell>
                                            <TableCell align="center">
                                                <Field
                                                    as={TextField}
                                                    type="time"
                                                    name={`WorkingHours[${index}].openingHours`}
                                                    value={dayData.openingHours}
                                                    error={touched.WorkingHours && Boolean(errors.WorkingHours)}
                                                    helperText={touched.WorkingHours && errors.WorkingHours}
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textFieldTime}
                                                    onChange={(e) => handleChange(index, "openingHours", e.target.value)}


                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Field
                                                    as={TextField}
                                                    type="time"
                                                    name={`WorkingHours[${index}].lunchStart`}
                                                    value={dayData.lunchStart}
                                                    error={touched.WorkingHours && Boolean(errors.WorkingHours)}
                                                    helperText={touched.WorkingHours && errors.WorkingHours}
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textFieldTime}
                                                    onChange={(e) => handleChange(index, "lunchStart", e.target.value)}

                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Field
                                                    as={TextField}
                                                    type="time"
                                                    name={`WorkingHours[${index}].lunchEnd`}
                                                    value={dayData.lunchEnd}
                                                    error={touched.WorkingHours && Boolean(errors.WorkingHours)}
                                                    helperText={touched.WorkingHours && errors.WorkingHours}
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textFieldTime}
                                                    onChange={(e) => handleChange(index, "lunchEnd", e.target.value)}

                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Field
                                                    as={TextField}
                                                    type="time"
                                                    name={`WorkingHours[${index}].closingHours`}
                                                    value={dayData.closingHours}
                                                    error={touched.WorkingHours && Boolean(errors.WorkingHours)}
                                                    helperText={touched.WorkingHours && errors.WorkingHours}
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textFieldTime}
                                                    onChange={(e) => handleChange(index, "closingHours", e.target.value)}

                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    ></FieldArray>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                        > {(wkId !== null)
                            ? `${i18n.t("WorkingHours.buttons.edit")}`
                            : `${i18n.t("WorkingHours.buttons.add")}`}
                        </Button>
                    </div>

                </Form>
            )}
        </Formik>
    );
}

export default WorkingHoursForm;
